// Libraries
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, ResponsiveType, useDrawer, useModal, ModalType} from '@supermove/hooks';
import {User, UserModel, TeamModel, OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Phone, List} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import {ColumnDefinitionType} from '@shared/design/components/Table/components/TableBuilder';
import UserRole from '@shared/modules/User/enums/UserRole';
import UserStatus from '@shared/modules/User/enums/UserStatus';
import StaffCommunicationDrawer from 'modules/Communication/components/StaffCommunicationDrawer';
import {
  SelectedUserStatusType,
  TEAMS,
} from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContentV2';
import OfficeStaffCommunicationActionDrawer from 'modules/Organization/Settings/Staff/components/OfficeStaffCommunicationActionDrawer';
import OfficeStaffReinviteUserModal from 'modules/Organization/Settings/Staff/components/OfficeStaffReinviteUserModal';
import TeamsTable from 'modules/Organization/Settings/Staff/components/TeamsTable';
import ReinviteOfficeUserSuccessModal from 'modules/Organization/Settings/Users/List/components/ReinviteOfficeUserSuccessModal';
import DeleteUserModal from 'modules/User/components/DeleteUserModal';
import UpdateUserDrawer from 'modules/User/components/UpdateUserDrawer';

const TeamsTableContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const getUsersTableColumnDefinition = ({
  viewer,
  organization,
  refetch,
  isEnabledConnectedEmail,
  reinviteOfficeUserSuccessModal,
}: {
  viewer: UserModel;
  organization: OrganizationModel;
  refetch: () => void;
  isEnabledConnectedEmail: boolean;
  reinviteOfficeUserSuccessModal: ModalType;
}): ColumnDefinitionType<UserModel>[] => [
  {
    flex: 1,
    headerLabel: 'Name',
    cellText: (officeUser) => officeUser.fullName,
    mobileOptions: {
      isInHeader: true,
      rank: 0,
    },
  },
  {
    flex: 1,
    headerLabel: 'Login Email',
    cellText: (officeUser) => officeUser.email,
    ...(isEnabledConnectedEmail
      ? {
          secondary: {
            headerLabel: 'Connected Email',
            cellText: (officeUser) => officeUser.activeAccountGrant?.identifier,
            emptyText: 'Not connected',
          },
        }
      : {}),
    mobileOptions: {
      rank: 1,
    },
  },
  {
    flex: 1,
    headerLabel: 'Phone Number',
    cellText: (officeUser) => Phone.display(officeUser.phoneNumber),
    mobileOptions: {
      rank: 2,
    },
  },
  {
    flex: 1,
    headerLabel: 'Position',
    cellText: (officeUser) => User.getPosition(officeUser),
    mobileOptions: {
      rank: 3,
    },
  },
  {
    flex: 1,
    headerLabel: 'Role',
    cellText: (officeUser) => User.getRole(officeUser),
    mobileOptions: {
      rank: 4,
    },
  },
  {
    minWidth: 80,
    // @ts-expect-error TS(2322): Type '(officeUser: UserModel) => { text: string; onPress:...
    actions: (officeUser) => [
      ...List.insertIf(UserRole.hasOfficePermissions(viewer.role), {
        text: 'Communication',
        onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
        desktopIcon: Icon.CommentDots,
        actionHook: {
          hook: useDrawer,
          hookArgument: {
            name: `Staff Communication Drawer`,
          },
          renderComponent: ({
            isOpen,
            handleClose,
            hookKey,
          }: {
            isOpen: boolean;
            handleClose: () => void;
            hookKey: string;
          }) => {
            return (
              <OfficeStaffCommunicationActionDrawer
                key={hookKey}
                officeUser={officeUser}
                refetch={refetch}
                viewer={viewer}
                isOpen={isOpen}
                handleClose={handleClose}
              />
            );
          },
        },
      }),
      ...List.insertIf(
        UserRole.hasAdminPermissions(viewer.role) && officeUser.status === UserStatus.PENDING,
        {
          text: 'Resend invitation',
          onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
          actionHook: {
            hook: useModal,
            hookArgument: {name: 'ConfirmReinviteUserModal'},
            renderComponent: ({
              isOpen,
              handleClose,
              hookKey,
            }: {
              isOpen: boolean;
              handleClose: () => void;
              hookKey: string;
            }) => {
              return (
                <OfficeStaffReinviteUserModal
                  key={hookKey}
                  user={officeUser}
                  organization={organization}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  reinviteOfficeUserSuccessModal={reinviteOfficeUserSuccessModal}
                  refetch={refetch}
                />
              );
            },
          },
        },
      ),
      ...List.insertIf(UserRole.hasAdminPermissions(viewer.role), {
        text: 'Edit user',
        onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
        actionHook: {
          hook: useDrawer,
          hookArgument: {name: 'Update User Drawer'},
          renderComponent: ({
            isOpen,
            handleClose,
            hookKey,
          }: {
            isOpen: boolean;
            handleClose: () => void;
            hookKey: string;
          }) => {
            return (
              <UpdateUserDrawer
                key={hookKey}
                user={officeUser}
                isOpen={isOpen}
                handleClose={handleClose}
                handleSuccess={() => {
                  handleClose();
                  refetch();
                }}
                viewerRole={viewer.role}
              />
            );
          },
        },
      }),
      ...List.insertIf(UserRole.hasAdminPermissions(viewer.role), {
        text: 'Delete user',
        color: colors.red.warning,
        onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
        actionHook: {
          hook: useModal,
          hookArgument: {name: 'DeleteUserModal'},
          renderComponent: ({
            isOpen,
            handleClose,
            hookKey,
          }: {
            isOpen: boolean;
            handleClose: () => void;
            hookKey: string;
          }) => {
            return (
              <DeleteUserModal
                key={hookKey}
                isOpen={isOpen}
                handleClose={handleClose}
                userId={officeUser.id}
                refetch={refetch}
              />
            );
          },
        },
      }),
    ],
  },
];

const OfficeStaffUsersTable = ({
  selectedUserStatus,
  officeUsers,
  officeTeams,
  organization,
  isEnabledConnectedEmail,
  refetch,
  viewer,
}: {
  selectedUserStatus: SelectedUserStatusType;
  officeUsers: UserModel[];
  officeTeams: TeamModel[];
  organization: OrganizationModel;
  isEnabledConnectedEmail: boolean;
  refetch: () => void;
  viewer: UserModel;
}) => {
  const responsive = useResponsive();
  const reinviteOfficeUserSuccessModal = useModal({name: 'ReinviteOfficeUserSuccessModal'});

  switch (selectedUserStatus) {
    case UserStatus.ACTIVE:
    case UserStatus.PENDING:
    case UserStatus.INACTIVE:
      return (
        <React.Fragment>
          <Table
            itemKey={'uuid'}
            columnDefinitions={getUsersTableColumnDefinition({
              viewer,
              organization,
              refetch,
              isEnabledConnectedEmail,
              reinviteOfficeUserSuccessModal,
            })}
            emptyStateText={'No office staff to display'}
            items={officeUsers}
            isScrollable
          />
          {/* TODO(Hammad) use state to retain user name, implement in success modal */}
          {/* TODO(Hammad) this might make more sense as a toast */}
          <ReinviteOfficeUserSuccessModal
            isOpen={reinviteOfficeUserSuccessModal.isOpen}
            handleClose={reinviteOfficeUserSuccessModal.handleClose}
            title={`Invitation Sent!`}
            subtitle={`User has 7 days to accept the invitation.`}
          />
        </React.Fragment>
      );
    case TEAMS:
      return (
        <TeamsTableContainer responsive={responsive}>
          <TeamsTable teams={officeTeams} refetch={refetch} viewer={viewer} />
        </TeamsTableContainer>
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OfficeStaffUsersTable.fragment = gql`
  ${StaffCommunicationDrawer.fragment}
  ${UpdateUserDrawer.fragment}
  ${OfficeStaffCommunicationActionDrawer.fragment}
  ${OfficeStaffReinviteUserModal.fragment}

  fragment OfficeStaffUsersTable on User {
    id
    uuid
    fullName
    email
    phoneNumber
    position
    role
    status
    activeAccountGrant {
      id
      identifier
    }
    ...StaffCommunicationDrawer
    ...UpdateUserDrawer
    ...OfficeStaffCommunicationActionDrawer
    ...OfficeStaffReinviteUserModal
  }
`;

export default OfficeStaffUsersTable;
