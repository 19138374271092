// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon, PhoneInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useState, useQuery, useResponsive} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import UserRole, {RoleType} from '@shared/modules/User/enums/UserRole';
import SalesUserSearchDropdownInput from 'modules/Organization/Settings/Staff/components/SalesUserSearchDropdownInput';

const Row = Styled.View<{index?: number}>`
  flex-direction: row;
  align-items: flex-start;
  z-index: ${({index = 0}) => 100 - index};
`;

const Section = Styled.View<{index?: number}>`
  z-index: ${({index = 0}) => 100 - index};
`;

const FirstAndLastNameSearch = ({
  form,
  field,
  hasSelectedUser,
}: {
  form: any;
  field: string;
  hasSelectedUser: boolean;
}) => {
  const responsive = useResponsive();
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeSearch = useDebouncedCallback((text) => setSearchQuery(text), 250);

  const {data, loading} = useQuery(InviteOfficeAppUserInfoFields.query, {
    fetchPolicy: 'cache-and-network',
    skip: !searchQuery,
    variables: {
      searchQuery,
    },
  });

  // TODO(Hammad) The sales app version of this filters out officeUsersWithoutSalesProductUser, so I need to figure out how to not show users that already are in the office app
  const salesUsers = _.get(data, 'viewer.viewingOrganization.filteredSalesAppUsers', []);

  return (
    <React.Fragment>
      {hasSelectedUser && (
        <React.Fragment>
          <TertiaryButton
            text={'Reset Information'}
            iconLeft={Icon.Xmark}
            onPress={form.resetForm}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <Section index={0}>
        <Row index={0}>
          <SalesUserSearchDropdownInput
            index={0}
            options={getSalesUserOptions({salesUsers})}
            form={form}
            field={field}
            name={'names.0'}
            label={'First Name'}
            placeholder={'Enter first name'}
            disabled={hasSelectedUser}
            required
            searchQuery={searchQuery}
            handleChangeSearch={(search) => {
              handleChangeSearch(search);
              form.setFieldValue(`${field}.names.0`, search);
            }}
            loading={loading}
            isRequired
          />
          <Space width={16} />
          <SalesUserSearchDropdownInput
            index={1}
            options={getSalesUserOptions({salesUsers})}
            form={form}
            field={field}
            name={'names.1'}
            label={'Last Name'}
            placeholder={'Enter last name'}
            disabled={hasSelectedUser}
            required
            searchQuery={searchQuery}
            handleChangeSearch={(lastName) => {
              handleChangeSearch(lastName);
              form.setFieldValue(`${field}.names.1`, lastName);
            }}
            loading={loading}
            isRequired
          />
        </Row>
      </Section>
    </React.Fragment>
  );
};

const getSalesUserOptions = ({salesUsers}: {salesUsers: UserModel[]}) => {
  return salesUsers.map((officeUser) => {
    return {
      user: officeUser,
      label: officeUser.fullName,
      value: officeUser.id,
      description: officeUser.email,
    };
  });
};

const InviteOfficeAppUserInfoFields = ({
  form,
  field,
  viewer,
}: {
  form: any;
  field: string;
  viewer: UserModel;
}) => {
  const hasSelectedUser = !!_.get(form.values, `${field}.userId`);

  return (
    <React.Fragment>
      <FirstAndLastNameSearch form={form} field={field} hasSelectedUser={hasSelectedUser} />
      <Space height={16} />
      <Section index={1}>
        <FieldInput
          {...form}
          index={2}
          label={'Position'}
          name={`${field}.position`}
          isRequired
          isResponsive
          input={{
            placeholder: 'Enter position',
          }}
        />
        {/* TODO(Hammad) Reconcile the RoleArgument and RoleType types. RoleArgument is older and includes undefined */}
        {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewer.role as RoleType) && (
          <React.Fragment>
            <Space height={16} />
            <FieldInput
              {...form}
              component={DropdownInput}
              index={3}
              label={'Supermove Role'}
              name={`${field}.role`}
              isRequired
              isResponsive
              input={{
                options: UserRole.getDropdownOptions({viewerRole: viewer.role as RoleType}),
                setFieldValue: form.setFieldValue,
                placeholder: 'Select role',
                isPortaled: true,
                style: {flex: 1},
                isResponsiveSheet: true,
              }}
            />
          </React.Fragment>
        )}
        <Space height={16} />
        <FieldInput
          {...form}
          index={4}
          label={'Phone Number'}
          name={`${field}.phoneNumber`}
          component={PhoneInput}
          isResponsive
          input={{
            setFieldValue: form.setFieldValue,
            placeholder: 'Enter phone number',
          }}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          index={5}
          label={'Email'}
          name={`${field}.email`}
          isResponsive
          isRequired
          input={{
            placeholder: 'Enter email',
            disabled: hasSelectedUser,
          }}
        />
      </Section>
    </React.Fragment>
  );
};

InviteOfficeAppUserInfoFields.query = gql`
  ${SalesUserSearchDropdownInput.fragment}
  query InviteOfficeAppUserInfoFields($searchQuery: String) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        filteredSalesAppUsers(searchQuery: $searchQuery) {
          id
          fullName
          email
          productUsers {
            id
            supermoveProduct {
              id
              kind
            }
          }
          ...SalesUserSearchDropdownInput
        }
      }
    }
  }
`;

export default InviteOfficeAppUserInfoFields;
